import React from "react"

export const Contact = () =>{

    return(
        <section id='contact'>
            <div className='txt'>
                <h3>need to talk?</h3>
                <h1>Reach me out!</h1>
                <p>
                    <span>&#9993; </span>
                    faizzulahmam@gmail.com
                </p>
            </div>
        </section>
    )
}